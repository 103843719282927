.h-form-absence {
  height: calc(100vh - 260px);

  &-body {
    height: calc(100vh - 136px);
  }
}

.h-list-absence {
  height: calc(100vh - 370px);
}