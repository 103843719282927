.h {
  &-birthday {
    height: calc(100vh - 270px);
  }

  &-bank {
    height: calc(100vh - 316px);
  }
}

.grid {
  &-bank {
    display: grid;
    grid-template-columns: 15% 30% 30% 10%;
    gap: 12px;
    align-items: center;
    justify-content: center;
  }

  &-crews {
    display: grid;
    grid-template-columns: 15% 15% 25% 20%;
    gap: 24px;
    align-items: center;
    justify-content: center;
  }

  &-cash {
    display: grid;
    grid-template-columns: 18% 18% 18% 18% 18%;
    gap: 12px;
    align-items: center;
    justify-content: center;
  }
}